.create-drawer-root {
  width: 25em;
  margin-top: 67px;
  height: 100%;
  .chevron-icon {
    cursor: pointer;
  }
  .create-project-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .project-card-container {
      display: flex;
      justify-content: space-between;
      .change-button-container {
        display: grid;
        width: fit-content;
        height: 100%;
        place-items: center;
      }
      .no-label-input {
        legend {
         width: 0px;
       }
     }
    }
    .buttons-container{
        padding: 1em;
    }
  }

  .project-type-list-container{
      padding: 1em;
      .grid-item{
          cursor: pointer
      }
  }
}
