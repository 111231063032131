@import "../../../theme/colors.module.scss";

.workflow-react-flow-path{
    stroke-width:4;
    &.selected{
        &.react-flow__edge-path{
            stroke: $primaryColor;
        }
        
    }
    
}