.MuiButton-root {
  &.fields-tab-row-root {
    width: 100%;
    .fields-tab-row-container {
      color: black;
      text-transform: none;
      justify-content: space-between;
      text-align: start;
      font-weight: normal;
      font-size: 15px;
      .children-centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.capitalize {
          text-transform: capitalize;
        }
        &.break-word {
          overflow-wrap: break-word;
        }

        .add-icon {
          font-size: 1.5em;
        }
      }
    }
  }
}
