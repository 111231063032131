.MuiFormGroup-root{
    &.form-group{
        align-items: center;
    }
}

.user-switch-field-root{
    .label{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}