.leaderboard {
  .leaderboard-person {
    width: fit-content;
    margin-right: 3em;
    align-items: center;

    .placement-div {
      margin-right: 0.4em;
      font-size: 2em;
    }
  }
}
