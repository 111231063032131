.MuiGrid-root {
  &.title-container {
    justify-content: space-between;
    .title {
      margin-right: 1em;
    }
    .search-bar {
        flex-grow: 1;
    }
  }
}
