@import "../../../theme/colors.module.scss";

.custom-workflow-node-root{
    background-color: $white;
    .node-metadata{
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;
        &.outlined{
            box-shadow: 0px 0px 8px 3px $primaryColor;
        }
        .label-container{
            width: 100%;
            display:flex;
            flex-direction: column;
            justify-content: center;
        }
        .icon-button{
            font-size: small;
        }
    }
}