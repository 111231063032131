@import "../../theme/colors";

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    padding: 60px 10px;
    min-height: calc(100vh - #{$footer-height}px);
    background: $white;
    margin-left: calc(70px + 10px);

    &.content-shift {
      transition: margin ease 0.5s;
      margin-left: calc(#{$drawer-width}px + 10px);
    }
  }
}
