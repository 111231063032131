@import "../../theme/colors";

.navigation-drawer {
  width: #{$drawer-width}px;
  flex-shrink: 0;
  white-space: nowrap;
  background-color: $toolbarColor;

  .drawer-open {
    width: #{$drawer-width}px;
    margin-top: 67px;
    transition: width ease-in 0.5s;
    background-color: $toolbarColor;
  }

  .drawer-close {
    margin-top: 66px;
    transition: width ease-out 0.5s;
    overflow-x: hidden;
    width: 70px;
    background-color: $toolbarColor;
  }

  .create-logo {
    margin-bottom: -13px;
    margin-top: 10px;
  }

  .logo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 4.2em;
      object-fit: cover;
      // width: 10%;
    }
  }
}
