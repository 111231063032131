@import "../../theme/colors.module.scss";
// $transparent-ab: transparentize($primaryColor, 0.8);
// $test: $primaryColor;
// $color-highlight: #755d57;

.livepreview {
  .tabs {
    .lp-tab {
      border-radius: 4px;
      border: 2px solid $adminColor30;
      padding: 0.2rem 0.3rem 0.1rem 0.3rem;
      //   width: 200px;

      background-color: $adminColor30;
      //   rgba(#{$primaryColor}, 0.5);
      //   opacity: 0.8;
      //   "rgba(var(--primary-color), 0.5)";

      //   &::before {
      //     opacity: 0.8;
      //     background-color: $primaryColor;
      //   }

      &.selected {
        border: 2px solid $primaryColor;
      }

      .lp-tab_header {
        flex-wrap: nowrap;

        .cancel-icon {
          @include pointer;
        }
      }
    }
    // .lp-tab::before {
    //   opacity: 0.8;
    //   background-color: $primaryColor;
    // }
  }
}
