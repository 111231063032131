@import "../../theme/colors";

.menu-item {
  .list-item-disabled {
    cursor: not-allowed;
  }

  .list-item{
  }
  .selected {
    transition: box-shadow;
    transition-duration: 1s;
    box-shadow: 0 0 3px $primaryColor, 0 0 9px $primaryColor,
      0 0 11px $primaryColor, 0 0 30px $primaryColor;
  }
}
