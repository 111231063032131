.search-bar {
  display: flex;
  justify-content: flex-end;
  .animated-search-input {
    width: 6em;
    transition: all 0.5s ease;
  }
  .transparent-box {
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: transparent;
      }
    }
    & .MuiOutlinedInput-root:hover {
      & fieldset {
        border-color: transparent;
      }
    }
  }
}
