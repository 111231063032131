@import "../../../theme/colors.scss";

$test: #{"rgba($primaryTextColor, 0.8)"};

.administration-container {
  flex-grow: 1;
  .MuiGrid-root {
    .paper {
      padding: 1em;
      text-align: left;
      cursor: pointer;
      box-shadow: 0px 4px 12px $cardOutline;
      &:hover {
        box-shadow: 0px 4px 20px 1px $primaryColor;
      }
      .admin-navlink {
        text-decoration: none;
        color: inherit;
        .icon {
          color: $primaryColor;
          margin-bottom: 10px;
        }
        .desc {
          margin-top: 10px;
        }
      }
    }
  }
}
