@import "../../../theme/colors.module.scss";

.MuiGrid-root {
  &.operator-operand-container {
    color: $white;
    background-color: $primaryColor;
    width: fit-content;
    height: fit-content;
    padding: 0.3em;
    margin: 0.3em;
    font-size: 1.5em;
    border-radius: 8px;
    .operator-content {
      font-size: 20px;
      padding-left: 1.3em;
    }
    .delete-container {
      font-size: 0.2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .close-icon {
        font-size: 18px;
      }
    }
  }
}
