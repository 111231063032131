$primaryTextColor: var(--primary-text-color);
$primaryColor: var(--primary-color);
$primaryColor85p: var(--primary-color-85p);
$secondaryColor: var(--secondary-color);
$secondaryTextColor: var(--secondary-text-color);

$ideasColor: var(--ideas-color);
$projectsColor: var(--projects-color);
$adminColor: var(--admin-color);
$adminColor30: var(--admin-color-30);
$casesColor: var(--cases-color);
$lightGrey: var(--light-grey-color);
$grey100: var(--grey-100-color);
$grey50: var(--grey-50-color);
$warning: var(--warning-color);
$white: var(--white-color);
$black: var(--black-color);
$dropColor: var(--drop-color);

$cardOutline: var(--card-outline);

//dark mode
$darkPrimaryColor: var(--dark-primary-color);

$footer-height: 30;
$header-height: 60;
$drawer-width: 200;

$stageColor: #FFA048;
$reviewStageColor: #FE6565;
$conditionColor: #75B84E;

:export {
  grey50: $grey50;
  grey100: $grey100;
  white: $white;
  primaryColor: $primaryColor;
  stageColor: $stageColor;
  reviewStageColor: $reviewStageColor;
  conditionColor: $conditionColor;
}
@mixin uppercase {
  text-transform: uppercase;
}

@mixin pointer {
  cursor: pointer;
}
