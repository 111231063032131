.MuiSelect-root {
  &.select {
    align-items: left;
    min-width: 142px;
    // margin: 15px 0;
  }
}

.edison-select_icon {
  margin-right: 5px;
}
