@import "../../theme/colors.module.scss";

.MuiGrid-root {
  &.calculated-field-container {
    .if-column {
      padding-top: 1em;
      justify-content: center;
    }
    .tabs-box {
      height: 40em;
    }
    .expression-box-label-container {
      padding-top: 0.5em;
      padding-bottom: 1em;
      justify-content: space-between;
      flex-direction: row;
      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .expression-box {
      height: fit-content;
      min-height: 36em;
      border: 1px solid $lightGrey;
      border-radius: 5px;
      padding: 1em;
      align-content: flex-start;
      margin-bottom: 1em;
    }
    .error-box {
      margin-top: 0.5em;
      color: red;
      height: 3em;
    }
  }
}
