.expandable-menu {
    &.sticky {
      position: sticky;
      top: 0;
    }
    .expandable-menu_header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }
  