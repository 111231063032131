.MuiTableRow-root{
    &.table-row{
        .MuiTableCell-root{
            border-bottom: unset;
        }
    }


}


