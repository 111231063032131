@import "../../theme/colors.module.scss";

$min-height: 50em;

.workflow-designer-page-root {
  //background-color: $grey100;
  border-radius: 4px;
  .top-bar{
    justify-content: space-between;
    .option-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      }
  }


  .body {
    width: 100%;
    height: 100%;
    .options-zone {
      min-height: $min-height;
      width: 8em;
      max-width: 8em;
      &.compact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 2em;
      }
    }
    .work-zone-container{
      flex-grow: 1;
      .work-zone {
        height: $min-height;
        width: 100%;
        min-height: $min-height;
      }
    }

    .node-options-zone {
      height: $min-height;
      min-height: $min-height;
      overflow-y: auto;
      width: 25em;
      max-width: 25em;
      &.compact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 3em;
        
      }
      .title {
        margin-bottom: 1em;
        font-weight: bold;
        font-size: large;
      }
    }
  }
}

// }
