.workflow-select-root {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    
    .workflow-select{
      min-width: 25em;
    }
    .new-workflow-container{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }
}
