.date-picker {
  .form-helper-styling {
    height: 1em;
  }
}
.user-date-picker {
  .read-only {
    .Mui-disabled {
      color: inherit;
      -webkit-text-fill-color: inherit;
    }
    fieldset {
      border-style: none;
    }
  }
}
