@import "../../theme/colors";

$loginPrimaryColor: #41c0f0;

.unauthenticated-view {
  flex-grow: 1;
  .app-bar {
    border-bottom: 3px solid $primaryColor;
    z-index: 1200;
  }
  .drawer-close {
    flex-shrink: 0;
    white-space: "nowrap";
    margin-top: 66px;
    overflow-x: "hidden";
    width: 20px;
    .toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;
    }
  }
  .content {
    flex-grow: 1;
    padding: 10px;
    border-right: 30px solid $primaryColor;
    min-height: calc(100vh - 50px);
    background: $secondaryColor;
    margin-left: 20px;
  }
}
.login-dialog {
    width: 35%; //This will need a media query when we add responsivness
    margin: auto;

    .MuiDialog-paper {
        padding: 20px;
        border-radius: 15px;
    }

    .dialog-logo {
        text-align: center;

        .login-logo {
            width: 75%;
        }
    }

    .dialog-content {
        text-align: center;

        .login-button {
            // Login page is a special place, where our colors haven't been loaded, so we will need to hardcode this
            border: 1px solid $loginPrimaryColor;
            color: $loginPrimaryColor;
            padding: 10px;
            width: 50%;
            height: 5em;
        }
    }
}
