.edison-typography {
  .edison-typography-box {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.uppercase {
    text-transform: uppercase;
    display: block;
  }
}
