.imageField {
  .root {
    border: 2px dashed #6b6b6b;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    row-gap: 10px;
  }
  .dropzone {
    font-size: 1.25em;
    min-height: 75px;
    min-width: 100px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }
  .fileInputContainer {
    align-items: center;
    justify-content: center;
    color: #6b6b6b;
  }
  .form-helper-styling {
    height: 1em;
    margin-bottom: 40px;
    p {
      font-size: 16px;
    }
  }
  .thumbContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 0;
    max-width: 350px;
    width: auto;
    height: auto;
    padding: 4px;
    box-sizing: border-box;
  }
  .thumbInner {
    width: 100%;
    overflow: hidden;
  }
  .img {
    width: 100%;
    height: auto;
  }
  .imgDisabled {
    opacity: 0.6;
  }
  .thumbAction {
    width: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  .actionButton {
    cursor: pointer;
    width: 100%;
    height: 20px;
  }
  .editButton {
    @extend .actionButton;
  }
  .deleteButton {
    @extend .actionButton;
  }
}
