@import "../../theme/colors.module.scss";

.usercomponent-parent {
  display: flex;
  border: 1px solid $white;
  border-radius: 4px;

  &:hover {
    border: 1px solid $primaryColor;
  }

  .usercomponent {
    height: auto;
    padding: 10px;
    cursor: pointer;
    width: 100%;
  }

  .pointer {
    margin-top: 25px;
  }
}
