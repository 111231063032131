@import "../../../../theme/colors.module.scss";
.condition-options-root{
    .expression-box{
        min-height: 10em;
        height: fit-content;
        border: 1px solid $lightGrey;
        border-radius: 5px;
        padding: 1em;
        align-content: flex-start;

    }

    .tabs-box{
        max-height: 20em;
        height: 20em;
    }
    .toggle-container{
        display: flex;
        justify-content: space-between;
    }
}