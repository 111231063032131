@import "../../theme/colors.module.scss";

.columns {
  .column-dragger {
    // border-right: 5px solid $light-grey;

    z-index: 100;
    cursor: ew-resize;
  }
  .column {
    border: 1px solid $lightGrey;
    // border-right: 5px solid $light-grey;
    // flex: 1 1 100%;
    padding: 10px;
    height: fit-content;
    display: flex;
    resize: horizontal;
    overflow: auto;

    .column_header {
      display: flex;
      justify-content: space-between;
    }
    .pointer {
      cursor: pointer;
    }

    .column-content {
      flex: 2;
      margin-left: 5px;
    }

    .re-size {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .re-size_arrow {
        border: 1px solid $lightGrey;
        border-radius: 20px;
        position: absolute;

        background-color: $white;
        cursor: pointer;

        &.re-size_arrow-right {
          margin-left: 10px;
        }

        &.re-size_arrow-left {
          margin-left: -10px;
        }
      }
    }
  }
}
