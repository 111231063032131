@import "../../theme/colors";

.app-bar {
  border-bottom: 3px solid $primaryColor;
  z-index: 1;
  transition: width ease 2s, margin ease 2s;

  .toolbar {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 1em 0 1em;
    .left-content {
      flex: 2;
      display: flex;
      flex-direction: row;
    }

    .right-content {
      display: flex;
      margin-top: 15px;
    }
  }
}
