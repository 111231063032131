.buttons-page-container{
    flex-grow: 1;
    .MuiGrid-root{
        &.meta-data-card{
            justify-content: space-between;
            .description-container{
                flex: 3;

                .description-card-text{
                    margin-bottom: 1.2em;
                }

                .tokens-list{
                    padding:0;
                    list-style: none;
                    margin-top: 0.5em;
                }
            }

            .create-button{
                flex:1;
                margin: auto;
                display: flex;
                justify-content: center;
            }
        }
    }
}