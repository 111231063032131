.MuiGrid-root {

    &.card-features-container{
        padding-top: 15px;
        padding-bottom: 15px;
        .drag-drop-container{
            align-self: center;
            padding-left: 0.4em;
            .drag-drop-heading{
                opacity: 0.6;
            }
        }
        .tab-container{
            width: 100%;
        }
    }
}