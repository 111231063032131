.node-events-root{
    .single-action-row{
        display: flex;
        justify-content: space-between;
        .action-title-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

 .add-event-button{
     width: max-content;
 }
}