
.card-designer-preview-container{
    .MuiPaper-elevation{
        min-height:50em;
        &.card{
            margin-top: 0;
        }
    }
}
.card-designer-button-container{
    margin-top: 0.5em;
    display: flex;
    justify-content: flex-end;
}