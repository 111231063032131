.node-title-root{
    padding: 10px 5px 10px 5px;
    .node-title-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .node-title{
            margin-left: 1em;
        }
    }
}