.view-project-root {
  min-height: 100%;
  .projects-content {
    transition: width 0.5s;
    width: 95%;
    &.flyout-open {
      transition: width 0.5s;
      width: 70%;
    }
    .top-bar {
      display: flex;
      justify-content: space-between;
      .title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .flyout {
    padding-top: 1em;
    transition: width 0.5s;
    width: 5%;
    &.flyout-open {
      transition: width 0.5s;
      width: 30%;
    }
    .flyout-top-toolbar {
      display: flex;
      justify-content: space-between;
      padding: 0 1em 0 1em;

    }
    .chevron{
        cursor: pointer;
    }
    .flyout-content{
        padding: 0.5em;
        width: 100%;
        .flyout-image{
            height: 15em;
            border-radius: 10px;
        }
    }

  }
}
