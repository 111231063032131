@import "../../../theme/colors.module.scss";
.MuiGrid-root {
  &.condition-row {
    min-height: 4em;
    margin-top: 1em;
    border-radius: 4px;
    &.grey {
      background-color: $lightGrey;
    }
    &.secondary {
      background-color: $secondaryColor;
    }
    .operator-box {
      display: flex;
      justify-content: space-between;
      padding-left: 0.5em;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      &.is-selected {
        border-left: 5px solid $primaryColor;
      }
      .condition-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .remove-row-box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-direction: column;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .remove-row-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
