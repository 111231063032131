.new-table-page-root {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-top: 1em;
  .new-table-text-inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  .new-table-option-inputs {
    width: 25vw;
    div:first-child {
      width: 70%;
    }
  }

  .copy-from-table-container {
    margin-top: 0.5em;
    display: flex;
    justify-content: flex-start;
    column-gap: 2em;
    .copy-button {
      align-self: flex-end;
      button {
        height: 55px;
        padding: 0 2.5em;
      }
    }
    div:first-child {
      width: 70%;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }

  .button-container {
    button:first-child {
      color: var(--secondary-color);
    }
  }
}
