
.MuiGrid-root {
    &.new-field-page-root {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: left;
      .field-metadata{
        margin-bottom: 1em;
      }
      .button-container{
          display: flex;
          justify-content: flex-end;
      }
    }
  }