.avatar-menu-item-root {
  .MuiGrid-root {
    &.avatar-popover-container {
      padding: 1em;

      .popover-row-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1em;
      }
    }
  }

  .avatar-container {
    padding-left: 0.8em;
    :hover {
      cursor: pointer;
    }
    .avatar{
        margin-right: 1em;
    }
    .user-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
