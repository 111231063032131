.multi_choice_popover {
  .add_icon {
    display: flex;
  }
  align-items: center;
  justify-content: center;
  .add_choice_button{
      margin-top: 1em;
  }
}
.popover {
  padding: 1em;
  .button_container {
    display: flex;
    flex-direction: column;
    justify-content: bottom;
    width: fit-content;
  }
}
