.MuiCard-root {
  &.content-card {
    max-width: 344px;
    padding-bottom: 1em;

    .icon-image {
      width: 2.5em;
      height: 2.5em;
      margin-right: 0.5em;
    }

    .title {
      text-transform: uppercase;
    }
    .title-container {
      padding-right: 1em;
      padding-left: 1em;
    }

    .description-box {
      width: 100%;
    }
    .icons-row {
      justify-content: space-between;
      padding: 0 0.5em 0 0.5em;
    }

    .zone-box {
      margin-top: 1em;
    }
    .placeholder-zone-container {
      padding: 0 1em 0 1em;
    }
    .placeholder-zone{
        height: 8em;
        background:#d3d3d3 ;
        margin-top: 0.5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: large;
    }
  }
}
