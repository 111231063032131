@import "../../theme/colors.module.scss";

.discover-page-root{
    .discover-toolbar{
        justify-content: space-between;
        .white{
            color:white;
        }
    }
    .item{
        cursor: pointer;
        &:hover {
            box-shadow: 0px 4px 20px 1px $primaryColor;
          }
    }
}