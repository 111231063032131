$grey50: #f8f8f8;
$greyBorder: #ececec;
$white: #ffffff;
$black: #000000;

.tabs {
  .tabs-titles {
    list-style: none;
    padding: 0px;

    margin: 0px;

    .tab-title {
      position: relative;
      background-color: $grey50;
      border: 1px solid $grey50;
      border-bottom: none !important;
      display: inline-block;
      padding: 20px;
      cursor: pointer;
      -moz-border-radius: 0px;
      -webkit-border-radius: 15px 15px 0px 0px;
      border-radius: 15px 15px 0px 0px;

      &.active {
        background-color: $white;
        border: 1px solid $greyBorder;
        border-bottom: none;
        color: $black;
        z-index: 1;

        &:after {
          position: absolute;
          content: "";
          width: 100%;
          bottom: -1px;
          left: 0;
          border-bottom: 2px solid $white;
          z-index: 2;
        }
      }
    }
  }

  .tab-content {
    background: $white;
    border-top: 1px solid $greyBorder;
    box-sizing: border-box;
    padding: 10px 0px;
    min-height: 100px;
  }
}
