.MuiGrid-root {
  &.form-designer-properties {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    .title {
      //TODO: Add typography from theme     title: {...theme.typography.h5, marginBottom: "5px",},
      margin-bottom: 5px;
    }
  }
}
