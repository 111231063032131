.edison-flyout {
  .MuiPaper-root {
    max-width: 33%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .flyout-title {
      display: flex;
      flex-direction: row;
      padding: 10px;
      .MuiTypography-root {
        margin-left: 10px;
      }
    }
    .flyout-content {
      padding: 10px;
    }
    .flyout-buttons {
      padding: 10px;
      button {
        margin-right: 10px;
      }
    }
  }
}
