.node-form-root {
  .node-form-select {
    flex-grow: 1;
  }
  .nav-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
