
.MuiGrid-root {
    &.new-button-page-root {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: left;
      .button-container{
          display: flex;
          justify-content: flex-end;
      }
    }
  }