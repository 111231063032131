@import "../../theme/colors.module.scss";

.dropzone {
  flex: 0 0 auto;
  height: 25px;
  transition: 200ms all;

  //broke with addition of delete icon
  //   &:nth-of-type(2n) {
  //     display: none;
  //   }

  &.horizontalDrag {
    width: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active:before {
      content: "+";
    }
  }

  &:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
  }

  &.active {
    color: $black;
    background: $dropColor;
    transition: 100ms all;
  }

  &.active:before {
    content: "Drop to add new zone";
    color: $lightGrey;
    margin: auto;
  }
}
