.MuiGrid-root {
  &.linked-tables-container {
    display: flex;
    justify-content: space-between;
    margin: 1em 0 1em 0;
    .sub-table {
      flex: 1;
      width: 95%;
      border: 1em;
    }
    .left-margin{
        margin-left: 1em;
    }
  }
}
