.react-flow-root {
  .custom-controls {
    width: 98%;
    top: 10px;
    left: 10px;
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
