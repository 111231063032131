$primaryTextColor: var(--primary-text-color);
$primaryColor: var(--primary-color);
$adminColor30: var(--primary-color-30);
$secondaryColor: var(--secondary-color);
$secondaryTextColor: var(--secondary-text-color);

$ideasColor: var(--ideas-color);
$projectsColor: var(--projects-color);
$adminColor: var(--admin-color);
$casesColor: var(--cases-color);
$lightGrey: var(--light-grey-color);
$grey100: var(--grey-100-color);
$grey50: var(--grey-50-color);
$warning: var(--warning-color);
$white: var(--white-color);
$black: var(--black-color);
$dropColor: var(--drop-color);
$cardOutline: var(--card-outline);
$toolbarColor: var(--toolbar-color);

//dark mode
$darkPrimaryColor: var(--dark-primary-color);

$footer-height: 30;
$header-height: 60;
$drawer-width: 200;

$stageColor: #ffa048;
$reviewStageColor: #fe6565;
$conditionColor: #75b84e;
