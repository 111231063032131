.types-page-root {
  flex-grow: 1;
  .types-page-button {
    color: white;
    text-align: right;
  }

  .MuiGrid-root {
    flex: 1;
    text-align: right;
  }
}

.edison-flyout {
  padding: 100px;
  z-index: 1;
  .flyout-content {
    form {
      padding: 5px 15px;
    }
    padding: 100px;
    .form-items {
      padding: 3px;
    }
  }
}
