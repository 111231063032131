@import "../../theme/colors.module.scss";

.row {
  // border-top: 1px dashed $light-grey;
  border: 1px solid $lightGrey;
  padding: 0;
  margin: "1em 1em 0 1em";

  .row_header {
    display: flex;
    justify-content: space-between;
  }

  .columns {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .title {
    margin: 0 0.5em 0 0.5em;
  }
  .pointer {
    @include pointer;
  }
}
