.multi_choice {
  .tree_item_icons {
    margin-left: 1em;
    align-items: center;
    width: max-content;
  }
  .label {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .parent_tree_item {
    margin-top: 1em;
  }
  .tree_item {
    margin-top: 0.5em;
  }
}
