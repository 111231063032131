.layout-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  .MuiTypography-root {
    &.title {
      //TODO: add typopgrapghy from theme =>     title: {...theme.typography.h5, },
      margin-bottom: 25px;
    }
  }

  //TODO : check if content class is still used =>
  // content: {
  //     flexGrow: 1,
  //     padding: theme.spacing(3),
  //     borderRight: `30px solid ${theme.palette.primary.main}`,
  //     background: theme.palette.background.paper,
  //     marginLeft: theme.spacing(7) + 1,
  //     [theme.breakpoints.up("sm")]: {
  //       marginLeft: theme.spacing(9) + 1,
  //     },
  //   },
}
