@import "../../../../theme/colors.module.scss";

.card-live-preview-row-root {
  margin: 0px 20px !important;

  .component-dropzone-container {
    width: 100%;
    .dropzone-visible {
      width: 20px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropzone-visible::before {
      content: "+";
    }
  }
  .cancel-icon {
    margin: 2px 2px 0 0;
    cursor: pointer;
  }
}
