@import "../../theme/colors";

.app-item {
  border-top: 5px solid $secondaryColor;
  padding-top: 10px;
  margin-right: 10px;
  transition: border-top;
  transition-duration: 1s;
  transition-timing-function: ease;

  &.selected {
    border-top: 5px solid $primaryColor;
    transition: border-top;
    transition-duration: 1s;
    transition-timing-function: ease;
  }

  .listItemDisabled {
    cursor: not-allowed;
  }
}
